import Avatar from '@mui/material/Avatar';
import DashboardIcon from "@mui/icons-material/Dashboard";
import ArrowBack from "@mui/icons-material/ArrowBack";
import StoreIcon from "@mui/icons-material/Store";
import Home from '@mui/icons-material/Home';
import LayersIcon from "@mui/icons-material/Layers";
import MenuIcon from '@mui/icons-material/Menu';
import GroupIcon from '@mui/icons-material/Group';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import RouterIcon from '@mui/icons-material/Router'; 
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import AppsIcon from '@mui/icons-material/Apps';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import VerifiedIcon from '@mui/icons-material/Verified';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../hooks';
import UserMenu from './ProfileMenu';
import { selectCompanyId, selectCurrentAuth, selectCurrentUser } from '../reducers/authSlice';
import { BaseRoute, defaultAppTitle, p } from '../enums';
import { useGetCompanyApplicationsQuery, useGetCompanyIotDevicesQuery, useGetCompanyRobotsQuery } from '../reducers/apiSlice';
import { iotDeviceSubId, noCompany } from '../utils';
import { Permission, Role, hasPermisssion } from '../model/security';

const drawerWidth = 200;

interface DrawerItem {
  text: string;
  icon: any;
  onclick: () => void;
  show: boolean;
}

export default function NavDrawer() {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [title, setTitle] = useState(defaultAppTitle)

  const user = useAppSelector(selectCurrentUser);
  const auth = useAppSelector(selectCurrentAuth)
  const companyId = useAppSelector(selectCompanyId);
  const location = useLocation();

  const {
    data: robots = []
  } = useGetCompanyRobotsQuery(companyId, { skip: companyId === noCompany.companyId })

  const {
    data: companyApplications = [],
  } = useGetCompanyApplicationsQuery({ companyId });

  const {
    data: iotDevices = [],
  } = useGetCompanyIotDevicesQuery({ companyId });

  useEffect(() => {
    let item = location.pathname.split("/")
    if (item[1] === BaseRoute.DEVICES) {
      setTitle('Devices')
      if (item.length >= 3) {
        const name: string = robots.find(x => x.id === item[2])?.name ?? item[2]
        if (item.length > 3) {
          setTitle(name + ' / ' + item[3].charAt(0).toUpperCase() + item[3].slice(1))
        } else {
          setTitle(name)
        }
      }
    } else if (item[1] === BaseRoute.APPLICATIONS) {
      setTitle("Applications")
      if (item.length >= 3) {
        const name: string = companyApplications.find(x => x.id === item[2])?.name ?? item[2]
        setTitle(name)
      }
    } else if (item[1] === BaseRoute.MODULES) {
      setTitle("Modules")
    } else if (item[1] === BaseRoute.SETTINGS) {
      setTitle("Settings")
    } else if (item[1] === BaseRoute.PROFILE) {
      setTitle("Profile")
    } else if (item[1] === BaseRoute.USERS) {
      setTitle("Users")
    } else if (item[1] === BaseRoute.CONTROL_UNITS) {
      setTitle("Control Units")
      if (item.length >= 3) {
        const name: string = iotDevices.find(x => x.id === item[2])?.id ?? item[2]
        setTitle(iotDeviceSubId(name))
      }
    }
    else if (item[1] === BaseRoute.RELEASES) {
      setTitle("Releases")
    } else if (item[1] === BaseRoute.AUDIT) {
      setTitle("Audit Logs")
    } else if (item[1] === BaseRoute.STORE) {
      setTitle("Store")
    } else {
      setTitle(defaultAppTitle)
    }
  }, [location, robots])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const navigate = useNavigate();

  const showBackButton = location.pathname !== p(BaseRoute.DASHBOARD) &&
    location.pathname !== p(BaseRoute.HOME) &&
    location.pathname !== p(BaseRoute.INTRO) &&
    location.pathname !== p(BaseRoute.USERS)
  let rightMenu: any = [];

  if (user) {
    rightMenu = (
      <Fragment>
        {/* <Badge badgeContent={4} color="secondary">
            <NotificationsIcon />
          </Badge> */}
        {showBackButton &&
          <Button onClick={() => navigate(-1)} color="inherit">
            <ArrowBack />
            Back
          </Button>
        }
        {!showBackButton &&
          <Avatar sx={{ bgcolor: 'secondary.main' }}
            onClick={handleProfileMenuOpen}
          >
            {(user.givenName ? user.givenName[0] : "")
              + (user.familyName ? user.familyName[0] : "")}
          </Avatar>
        }
      </Fragment>
    );
  } else {
    rightMenu = (
      <Button component={Link} to={p(BaseRoute.SIGNIN)} color="inherit">
        Login
      </Button>
    );
  }

  let drawerItems: DrawerItem[] = [];

  if (user) {
    if (companyId !== noCompany.companyId || auth.role === Role.SYSADMIN) {
      drawerItems = [
        {
          text: "Dashboard",
          icon: <DashboardIcon />,
          onclick: () => {
            setMobileOpen(!mobileOpen)
            navigate(p(BaseRoute.DASHBOARD))
          },
          show: true
        },
        {
          text: "Applications",
          icon: <AppsIcon />,
          onclick: () => {
            setMobileOpen(!mobileOpen)
            navigate(p(BaseRoute.APPLICATIONS))
          },
          show: true
        },
        {
          text: "Devices",
          icon: <PrecisionManufacturingIcon />,
          onclick: () => {
            setMobileOpen(!mobileOpen)
            navigate(p(BaseRoute.DEVICES))
          },
          show: true
        },
        {
          text: "Control Units",
          icon: <RouterIcon />,
          onclick: () => {
            setMobileOpen(!mobileOpen)
            navigate(p(BaseRoute.CONTROL_UNITS))
          },
          show: true
        },
        {
          text: "Modules",
          icon: <LayersIcon />,
          onclick: () => {
            setMobileOpen(!mobileOpen)
            navigate(p(BaseRoute.MODULES))
          },
          show: true
        },
        {
          text: "Users",
          icon: <GroupIcon />,
          onclick: () => {
            setMobileOpen(!mobileOpen)
            navigate(p(BaseRoute.USERS))
          },
          show: true
        },
        {
          text: "Audit Logs",
          icon: <VerifiedIcon />,
          onclick: () => {
            setMobileOpen(!mobileOpen)
            navigate(p(BaseRoute.AUDIT))
          },
          show: hasPermisssion(auth, Permission.READ_AUDIT) ? true : false
        },
        {
          text: "Releases",
          icon: <InboxIcon />,
          onclick: () => {
            setMobileOpen(!mobileOpen)
            navigate(p(BaseRoute.RELEASES))
          },
          show: hasPermisssion(auth, Permission.READ_RELEASES) ? true : false
        },
        {
          text: "Store",
          icon: <StoreIcon />,
          onclick: () => {
            setMobileOpen(!mobileOpen)
            navigate(p(BaseRoute.STORE))
          },
          show: true
        },
     ];
    } else {
      drawerItems = [
        { text: "Dashboard", icon: <Home />, onclick: () => navigate(p(BaseRoute.INTRO)), show: true },
      ];
    }
  } else {
    drawerItems = [
      { text: "Home", icon: <Home />, onclick: () => navigate(p(BaseRoute.HOME)), show: true },
    ];
  }

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {drawerItems.map((item) => {
          const { text, icon, onclick, show } = item;
          return (
            <ListItem key={text} sx={{ display: show ? 'block' : 'none' }} disablePadding>
              <ListItemButton onClick={onclick}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {title}
          </Typography>
          {rightMenu}
        </Toolbar>
        <UserMenu anchorEl={anchorEl} handleMenuClose={handleMenuClose} />
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="menu"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
