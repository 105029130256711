import AddIcon from '@mui/icons-material/Add';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import { t } from "i18next";
import { useState } from "react";
import { useAppSelector } from "../../hooks";
import { Part } from "../../model/model";
import { useAddNewPartMutation } from "../../reducers/apiSlice";
import { selectCompanyId } from "../../reducers/authSlice";
import DialogTitleBox from '../DialogTitleBox';
import { ErrorSnackbar, SuccessSnackbar } from "../SnackbarMsg";

export default function AddPart() {
  const [open, setOpen] = useState(false);
  const companyId = useAppSelector(selectCompanyId)

  const [addPartData, setAddPartData] = useState({
    id: '',
    mcVersion: '',
    firmwareVersion: '',
    utilisation: ''
  })

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAddPartData({ id: '', mcVersion: '', firmwareVersion: '', utilisation: '' })
  };

  const [addPart, { isSuccess, isError, error }] = useAddNewPartMutation()

  const allFieldsValidation = addPartData.firmwareVersion.length > 0 &&
    addPartData.id.length > 0 &&
    addPartData.utilisation.length > 0 &&
    addPartData.mcVersion.length > 0

  const addPartHandler = async (event: any) => {
    event.preventDefault();

    const newPart: Part = {
      id: addPartData.id,
      mcVersion: addPartData.mcVersion,
      firmwareVersion: addPartData.firmwareVersion,
      utilisation: addPartData.utilisation
    }

    await addPart({ part: newPart, companyId })
      .then(() => {
        setAddPartData({ id: '', mcVersion: '', firmwareVersion: '', utilisation: '' })
        handleClose();
      })
      .catch(() => {
        <ErrorSnackbar error={error} />
      })
  }

  let content

  if (isSuccess) {
    content = <SuccessSnackbar message='Added' />
  } else if (isError) {
    content = <ErrorSnackbar error={error} />
  }

  return (
    <>
      {content}
      <Button variant="outlined" endIcon={<AddIcon />} onClick={handleClickOpen}>
        Add Module
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
      >
        <DialogTitleBox onClose={handleClose}>Add</DialogTitleBox>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            label={`${t('serial-number')}`}
            margin="dense"
            value={addPartData.id}
            placeholder="BCA04A0-1001"
            onChange={(e) => setAddPartData({ ...addPartData, id: e.target.value })}
          />
          <TextField
            label={`${t('firmware')}`}
            margin="dense"
            placeholder="58f711c2b1"
            value={addPartData.firmwareVersion}
            onChange={(e) => setAddPartData({ ...addPartData, firmwareVersion: e.target.value })}
          />
          <TextField
            label={`${t('mc-version')}`}
            margin="dense"
            placeholder="BCA04A0"
            value={addPartData.mcVersion}
            onChange={(e) => setAddPartData({ ...addPartData, mcVersion: e.target.value })}
          />
          <TextField
            label={`${t('utilisation')}`}
            margin="dense"
            value={addPartData.utilisation}
            placeholder="1275"
            onChange={(e) => setAddPartData({ ...addPartData, utilisation: e.target.value.replace(/\D/g, '') })}
            InputProps={{
              startAdornment: <HourglassBottomIcon />,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={addPartHandler} disabled={!allFieldsValidation}>Add</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}