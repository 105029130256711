import { Alert, Backdrop, Box, CardMedia, CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import { useAppSelector } from '../../hooks';
import { useGetPartsQuery } from '../../reducers/apiSlice';
import { selectCompanyId } from '../../reducers/authSlice';
import { getErrorMessage, imageForPart, partUid } from '../../utils';
import DeletePart from './DeletePart';
import EditPart from './EditPart';
import AddPart from './AddPart';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { GridToolbar } from '@mui/x-data-grid';
import { useTranslation } from "react-i18next";
import { Suspense, useState } from "react";
import { ComponentType, getComponentDefinition } from "../../model/enums";
import { BaseRoute, p } from "../../enums";
import { Part } from "../../model/model";
import { useNavigate } from "react-router-dom";
import Container from '@mui/material/Container';

export const PartsEdit = () => {

  const companyId = useAppSelector(selectCompanyId) ?? undefined
  const { t } = useTranslation();
  const theme = useTheme();
  const showSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const [pageSize, setPageSize] = useState<number>(50);

  const columns: GridColDef[] = [
    {
      field: 'Image',
      headerName: 'Image',
      flex: 2,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <CardMedia
            sx={{
              width: 'auto',
              mixBlendMode: 'multiply'
            }}
            component="img"
            alt="Part Image"
            height="35px"
            image={imageForPart(params.row)}
          />
        )
      }
    },
    {
      field: 'id',
      headerName: `${t('serial-number')}`,
      flex: 2,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'utilisation',
      headerName: `${t('utilisation')}`,
      flex: 2,
      align: 'center',
      headerAlign: 'center',
      hide: !showSmUp ? true : false
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      flex: 2,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <EditPart part={params.row} />
            <DeletePart part={params.row} />
          </Box>
        )
      }
    },
  ];

  const {
    data: parts = [],
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetPartsQuery({ companyId: companyId })

  const navigate = useNavigate()

  const partClickHandler = (part: Part) => {
    const componentType = getComponentDefinition(part.mcVersion)?.type ?? ComponentType.UNKNOWN
    const pUid = partUid(componentType, part.id)
    navigate(`${p(BaseRoute.MODULES)}/${pUid}`)
  }

  let content

  if (isLoading) {
    content = <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  } else if (isSuccess) {
    if (Object.keys(parts).length === 0) {
      content = <Alert severity="info">No parts found.</Alert>
    } else {
      content =
        <Box sx={{ height: '100vh', width: '100%' }}>
          <DataGrid
            rows={parts}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={[50, 100]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            getRowId={(part) => part.id}
            onRowClick={(row) => partClickHandler(row.row)}
            experimentalFeatures={{ newEditingApi: true }}
            components={{ Toolbar: GridToolbar }}
            sx={{ cursor: 'pointer' }}
          />
        </Box>
    }
  } else if (isError) {
    content = <Alert severity="error">{getErrorMessage(error)}</Alert>
  }

  return (
    <Container maxWidth='lg' sx={{ background: 'white' }}>
      <Suspense fallback={<p>Loading Translations ...</p>}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', p: 2 }}>
          <AddPart />
        </Box>
        {content}
      </Suspense>
    </Container>
  );
}