import {
  Box, Button, Card, CardActionArea, CardActions,
  Backdrop, CardHeader, CardMedia, Chip, CardContent, 
  CircularProgress, Alert
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BaseRoute, SubRoute, p } from "../enums";
import { useAppSelector } from "../hooks";
import { getIotDeviceState } from "../model/enums";
import { useGetCompanyIotDevicesQuery } from "../reducers/apiSlice";
import { selectCompanyId } from "../reducers/authSlice";
import { getErrorMessage, iotDeviceSubId, timeSince } from "../utils";

export const ControlUnits = () => {

  const companyId = useAppSelector(selectCompanyId)
  const navigate = useNavigate();

  const {
    data: iotDevices = [],
    isLoading,
    isError,
    isSuccess,
    error
  } = useGetCompanyIotDevicesQuery({ companyId });

  const handleDetails = (iotDeviceId: string) => {
    navigate(`/${BaseRoute.CONTROL_UNITS}/${iotDeviceId}/${SubRoute.SOFTWARE}`);
  };

  let content

  if (isLoading) {
    content = <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  } else if (isSuccess) {
    if (Object.keys(iotDevices).length === 0) {
      content = <Alert severity="info">No Control Units found.</Alert>
    } else {
      content =
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
          {
            iotDevices.map((device) => {
              const state = getIotDeviceState(device.status)
              return (
                <Card key={device.id}
                  sx={{
                    m: 1, width: { xs: '165px', sm: '200px' },
                    display: 'flex', alignItems: 'center', flexDirection: 'column'
                  }}>
                  <CardActionArea onClick={() => handleDetails(device.id)}>
                    <CardHeader
                      sx={{ textAlign: 'center', overflow: 'hidden', whiteSpace: 'nowrap' }}
                      title={iotDeviceSubId(device.id)}
                      subheader={timeSince(device.lastStatusUpdateTimestamp) + ' ago'}
                    />
                    <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Chip label={state.name} style={{ color: state.color, backgroundColor: state.background }} />
                    </CardContent>
                    <CardMedia
                      sx={{
                        width: 'auto',
                        margin: 'auto',
                        mixBlendMode: 'multiply'
                      }}
                      component="img"
                      alt="Control Unit Image"
                      height="70px"
                      image='/parts/CUA01A1.png'
                    />
                  </CardActionArea>
                  <CardActions>
                    <Button onClick={() => handleDetails(device.id)}>Details</Button>
                  </CardActions>
                </Card>
              )
            })
          }
        </Box>
    }
  } else if (isError) {
    content = <Alert severity="error">{getErrorMessage(error)}</Alert>
  }

  return (
    <>
      {content}
    </>
  )
}