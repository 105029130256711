import EditIcon from '@mui/icons-material/Edit'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, TextField } from "@mui/material"
import { t } from 'i18next'
import { ChangeEvent, useState } from "react"
import { useAppSelector } from "../../hooks"
import { Part } from "../../model/model"
import { useUpdatePartMutation } from "../../reducers/apiSlice"
import { selectCompanyId } from "../../reducers/authSlice"
import DialogTitleBox from '../DialogTitleBox'
import { ErrorSnackbar, SuccessSnackbar } from "../SnackbarMsg"

export default function EditPart(props: { part: Part }) {
  const { part } = props

  const [updatePart, { isSuccess, isError, error }] = useUpdatePartMutation();

  const companyId = useAppSelector(selectCompanyId)

  const [firmwareVersion, setFirmwareVersion] = useState(part.firmwareVersion)
  const [utilisation, setUtilisation] = useState(part.utilisation)

  const firmwareVersionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFirmwareVersion(e.target.value)
  }
  const utilisationChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUtilisation(e.target.value.replace(/\D/g, ''))
  }

  const [open, setOpen] = useState(false);

  const handleClickOpen = (e:any) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUtilisation(part.utilisation)
    setFirmwareVersion(part.firmwareVersion)
  };

  const handleSaved = () => {
    setOpen(false)
  }

  const editValidation = part.firmwareVersion !== firmwareVersion || part.utilisation !== utilisation

  const updatePartHandler = async () => {
    await updatePart({
      partId: part.id,
      companyId: companyId,
      part: {
        utilisation: utilisation,
        firmwareVersion: firmwareVersion
      }
    }).then(() => {
      handleSaved();
    }).catch(() => {
      <ErrorSnackbar error={error} />
    })
  }

  let content;

  if (isSuccess) {
    content = <SuccessSnackbar message='Updated.'/>
  } else if (isError) {
    content = <ErrorSnackbar error={error} />
  }

  return (
    <>
      {content}
      <IconButton color='inherit' onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
      >
        <DialogTitleBox onClose={handleClose}>Edit</DialogTitleBox>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
          <DialogContentText sx={{pb:2}}>
            Update Module: <b>{part.id}</b>?
          </DialogContentText>
          <TextField
            margin="dense"
            label={`${t('mc-version')}`}
            value={part.mcVersion}
            size="small"
            disabled
          />
          <TextField
            margin="dense"
            label={`${t('firmware')}`}
            value={firmwareVersion}
            size="small"
            onChange={firmwareVersionChange}
          />
          <TextField
            margin="dense"
            label={`${t('utilisation')}`}
            value={utilisation}
            size="small"
            onChange={utilisationChange}
            InputProps={{
              startAdornment: <HourglassBottomIcon />,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={updatePartHandler}
            disabled={!editValidation || utilisation.length < 1 || firmwareVersion.length < 1}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}