import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Alert, Backdrop, CardMedia, CircularProgress,
  Collapse,
  Link,
  Table, TableBody, TableCell, TableContainer, TableRow,
  Box,
  useMediaQuery,
  useTheme
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import { Fragment } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { BaseRoute, p } from '../../enums';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { ComponentType, getComponentDefinition, getPartTypeCode } from '../../model/enums';
import { Part, Robot } from '../../model/model';
import { useGetCompanyRobotsQuery, useGetPartsQuery } from '../../reducers/apiSlice';
import { selectCompanyId } from '../../reducers/authSlice';
import { setPartsOpenView } from '../../reducers/uiSlice';
import { getErrorMessage, imageForPart, partUid, remainingLifePercents } from '../../utils';
import UtilProgress from '../UtilProgress';

type PartsSummary = {
  [index: string]: Part[]
}

const PartTypeHeadingRow = (props: { row: any, title: string, count: number }) => {

  const openViewName = useAppSelector((state) => state.ui.parts.openViewName);
  const dispatch = useAppDispatch();

  const open = props.title === openViewName;

  const toggleView = () => {
    dispatch(setPartsOpenView(open ? 'none' : props.title))
  }

  return (
    <>
      <TableRow sx={{ cursor: 'pointer' }} onClick={toggleView}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={toggleView}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {getComponentDefinition(props.title)?.name + 's'}
        </TableCell>
        <TableCell>
          {props.count}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableContainer sx={{ mt: 1 }}>
              <Table size="small">
                <TableBody>
                  {props.row}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

function PartInfoRow(props: { part: Part, inRobots: Robot[] }) {
  const { part, inRobots } = props;

  const navigate = useNavigate()

  const theme = useTheme();
  const showSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const partClickHandler = (part: Part) => {
    const componentType = getComponentDefinition(part.mcVersion)?.type ?? ComponentType.UNKNOWN
    const pUid = partUid(componentType, part.id)
    navigate(`${p(BaseRoute.MODULES)}/${pUid}`)
  }

  return (
    <>
      <TableRow sx={{ cursor: 'pointer' }} key={part.id}>
        <TableCell onClick={() => partClickHandler(part)} >
          {
            <CardMedia
              sx={{ width: 'auto', mixBlendMode: 'multiply' }}
              component="img"
              alt="Part Image"
              height="50px"
              image={imageForPart(part)}
            />
          }
        </TableCell>
        <TableCell align='center' onClick={() => partClickHandler(part)} >
          <UtilProgress value={remainingLifePercents(part)} />
        </TableCell>
        <TableCell align='center' onClick={() => partClickHandler(part)}>{part.id}</TableCell>
        {!showSmUp ? '' :
          <TableCell sx={{maxWidth: 300}} align='right'>
            {inRobots?.length > 0 ?
              <Box sx={{ display: "flex", flexWrap: 'wrap' }}>
                {inRobots?.map((device: Robot) =>
                  <Box key={device.id} component="span" sx={{ pl: 1 }}>
                    <Link to={`${p(BaseRoute.DEVICES)}/${device.id}`}
                      component={RouterLink}>{device.name}
                    </Link>
                  </Box>
                )}
              </Box>
              : <></>}
          </TableCell>}
      </TableRow>
    </>
  );
}

export default function PartsRead() {

  const companyId = useAppSelector(selectCompanyId)
  const {
    data: parts = [],
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetPartsQuery({ companyId: companyId ?? 'none' })

  const {
    data: robots = [],
  } = useGetCompanyRobotsQuery(companyId)

  // Determine in which robots is are the part used
  const robotsUsingPart: Robot[][] = [];

  robots.forEach((robot) => {
    robot.partIds.forEach((partId: any) => {
      if (!robotsUsingPart[partId]) {
        robotsUsingPart[partId] = [];
      }
      robotsUsingPart[partId].push(robot);
    });
  });

  const partByType: PartsSummary = {}
  parts.filter((part) => getComponentDefinition(part.mcVersion)?.type !== ComponentType.SBK_MODULE_CU).forEach(part => {
    if (part !== undefined) {
      let partType: string = getPartTypeCode(part.mcVersion)
      if (partByType[partType] === undefined) {
        partByType[partType] = [];
      }
      partByType[partType].push(part)
    }
  })

  let content
  if (isLoading) {
    content = <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  } else if (isSuccess) {
    if (Object.keys(parts).length === 0) {
      content = <Alert severity="info">No parts found.</Alert>
    } else {
      content = (
        <Table>
          <TableBody>
            {Object.keys(partByType).map((key: string) => (
              <Fragment key={key}>
                <PartTypeHeadingRow
                  title={key}
                  count={partByType[key].length}
                  row={partByType[key].map((part: any) => (
                    <PartInfoRow key={part.id} part={part} inRobots={robotsUsingPart[part.id]} />))}
                />
              </Fragment>
            ))}
          </TableBody>
        </Table>
      )
    }
  } else if (isError) {
    content = <Alert severity="error">{getErrorMessage(error)}</Alert>
  }
  return (
    <>
      {content}
    </>
  );
}
