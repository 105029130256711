import { CardMedia, Table, TableBody, TableCell, TableHead, TableRow, Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { BaseRoute } from "../../enums";
import { useAppSelector } from "../../hooks";
import { ComponentType, getComponentDefinition } from "../../model/enums";
import { Part } from "../../model/model";
import { useGetCompanyRobotPartsQuery } from "../../reducers/apiSlice";
import { selectCompanyId } from "../../reducers/authSlice";
import { getErrorMessage, imageForPart, partUid, remainingLifePercents } from "../../utils";
import UtilProgress from "../UtilProgress";

type Props = {
  robotId: string,
}

const RobotParts = ({ robotId }: Props) => {

  const navigate = useNavigate()
  const { t } = useTranslation();

  const companyId = useAppSelector(selectCompanyId)
  const {
    data: parts = [],
    isSuccess,
    isError,
    error,
  } = useGetCompanyRobotPartsQuery({ robotId, companyId: companyId }, { skip: robotId == null })

  const partClickHandler = (part: Part) => {
    const componentType = getComponentDefinition(part.mcVersion)?.type ?? ComponentType.UNKNOWN
    const pUid = partUid(componentType, part.id)
    navigate(`/${BaseRoute.MODULES}/${pUid}`)
  }

  let content
  if (isSuccess) {
    if (Object.keys(parts).length === 0) {
      content = <Alert severity="info">No parts found.</Alert>
    } else {
      content = (
        <Table>
          <TableHead>
            <TableRow sx={{ background: "aliceblue" }}>
              <TableCell align='left'>{t('image')}</TableCell>
              <TableCell align="center">{t('remaining-life')}</TableCell>
              <TableCell align="right">{t('serial-number')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {parts
              .map((part) => (
                <TableRow sx={{ cursor: 'pointer' }} onClick={() => partClickHandler(part)} key={part.id}>
                  <TableCell align='left'>
                    {
                      <CardMedia
                        sx={{
                          width: 'auto',
                          mixBlendMode: 'multiply'
                        }}
                        component="img"
                        alt="Part Image"
                        height="50px"
                        image={imageForPart(part)}
                      />
                    }
                  </TableCell>
                  <TableCell align="center">
                    {
                      <UtilProgress value={remainingLifePercents(part)} />
                    }
                  </TableCell>
                  <TableCell align="right">{part.id}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )
    }
  } else if (isError) {
    content = <Alert severity="error">{getErrorMessage(error)}</Alert>
  }

  return (
    <>
      {content}
    </>
  )
}

export default RobotParts;